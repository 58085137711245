import React, { useState, useEffect } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigate, useNavigate } from "react-router-dom";
import Axios from '../../utils/AxiosConfi';
import ApiNames from "../../Constants/ApiUrls";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Pagination, Autoplay } from 'swiper/modules';
import MySkeleton from "../../Component/Loaders/MySkeleton";

const CategoryCard = () => {
  const [getCategory, setCategory] = useState([]);
  const [getId, setId] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const getAllCategory = async () => {
      try {
        let url = ApiNames.getShowCategory
        const response = await Axios.get(
          `${url}`
        );
        setCategory(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllCategory();
  }, []);

  function handleItemClick(productId) {
    navigate(`/Filter/1/${productId}`)
  };
  return (
    <div>
      <ul className="list-unstyled category-itemTop">


        {getCategory.slice(0, 8).map((product) => (
          <li className="categorycard-list" key={product._id}>
            <div onClick={() => handleItemClick(product?.firstDetail?._id)} className="cards-top">

              <div className="Cimg-top">
                <div className="banner-text">
                  <p className="text-edit">{product?.firstDetail?.categoryName || <MySkeleton />}</p>
                </div>
                <img
                  src={product?.firstDetail?.thumbnail || <MySkeleton />}
                  alt=""
                  className="banner-insideImge"
                />
              </div>
            </div>
          </li>
        ))}

      </ul>


      <div className="categoryForMobiles">
        <Swiper
          slidesPerView={4}
          spaceBetween={24}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}

          navigation={false}
          modules={[Autoplay, Pagination]}
          className="mySwiperNew"
        >

          {getCategory.slice(0, 8).map((product,index) => (
            <SwiperSlide key={index}>

              <img onClick={() => handleItemClick(product?.firstDetail?._id)} style={{ widows: 200, height: 100, borderRadius: 6 }}
                src={product?.firstDetail?.thumbnail}
                alt=""
                className="banner-insideImge"
              />
              <p className="text-edit" style={{fontSize:'12px'}}>{product?.firstDetail?.categoryName || <MySkeleton />}</p>

            </SwiperSlide>

          ))}
        </Swiper>

      </div>





    </div>

  );
};

export default CategoryCard;
