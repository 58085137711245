import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import ApiNames from "../../Constants/ApiUrls";
import Axios from "../../utils/AxiosConfi";
import { Toaster } from "../../utils/Toaster";
import CreateAddress from "../../Pages/UserAddress/CreateAddress";
import EditAddress from "../../Pages/UserAddress/EditAddress";

const Profile = () => {
    const selectCountryNow = useRef(null)
    const PopModal = useRef(null)
    const [getImage, setImage] = useState('');
    const [getLoginType, setLoginType] = useState('');
    const [getMblNumber, setMblNumber] = useState('');
    const [getAddresId, setEditAddressId] = useState('');
    const [isProfileUpload, setisProfileUpload] = useState(false);
    const [changeValue, setValue] = useState(false);
    const [getName, setName] = useState('');
    const [getcountryList, SetcountryList] = useState([]);
    const [getAddres, setAddress] = useState([]);
    const [profileImage, setProfileImage] = useState('https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg');
    const [userInfo, setUserInfo] = useState({
        firstName: '',
        lastName: '',
        displayName: '',
        emailId: '',
        mobile: '',
        countryCode: '',
        profileImage: getImage
    });
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        country: "",
        countryCode: "",
        address: "",
        companyName: "",
        appartment: "",
        city: "",
        state: "",
        pincode: "",
        emailId: "",
        mobileNumber: ""
    });

    const [editedAddressData, setEditedAddressData] = useState({
        firstName: "",
        lastName: "",
        country: "",
        countryCode: "",
        address: "",
        companyName: "",
        appartment: "",
        city: "",
        state: "",
        pincode: "",
        emailId: "",
        mobileNumber: "",
        type: ""
    });

    const [errors, setErrors] = useState({});
    const [mobileNumber, setPhoneNumber] = useState('');
    const [getCountryCode, setCountryCode] = useState('');
    const [getNumber, setsetNumber] = useState('');
    const [addressType, setAddressType] = useState('');
    const [customAddressType, setCustomAddressType] = useState('');
    const buttonRef = useRef(null);
    const [getFlag, setFlag] = useState('');
    const buttonRefs = useRef(null);
    const fileInputRef = useRef(null);
    const [getCountry, setCountry] = useState([]);

    const formDatas = new FormData();

    const handleCustomAddressTypeChange = (event) => {
        setCustomAddressType(event.target.value);
    };
    const handlePhoneNumberChange = event => {
        const modifiedNumber = event.target.value
        setMblNumber(event.target.value)
    };

    const selectCOuntryS = () => {

        selectCountryNow?.current.focus()
    }

    const handleAddressTypeChange = (event) => {
        const selectedType = event.target.value;
        setAddressType(selectedType);
        if (selectedType !== 'Other') {
            setCustomAddressType('');
        }
    };
    const handleKeyUp = (event) => {
        setName(event.target.value)
    }
    function GetCode(value) {
        setFlag(value)
        userInfo.countryCode = value?.callingCode
        setsetNumber(value?.callingCode)
        setCountryCode(value?.callingCode)
    }

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setUserInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    useEffect(() => {
        const getProfileDetails = async () => {
            try {

                let api = ApiNames.Inssearch
                const response = await Axios.post(
                    `${api}`, { name: getName }
                );

                setFlag(response.data)
                getProfile()
                SetcountryList(response?.data)

            } catch (error) {
                console.error("Error checking login status:", error);
            }
        }
        getProfileDetails()
    }, [getName])
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = async (e) => {
        let file = e.target.files[0];
        setisProfileUpload(true)
        formDatas.append('images', file);
        if (file) {
            try {
                let token = await localStorage.getItem('token');
                let api = ApiNames.uploadImage;

                const response = await Axios.post(
                    `${api}`,
                    formDatas,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                setTimeout(() => {
                    setImage(response.data.imagePath);
                    setProfileImage(response.data.imagePath)
                    setisProfileUpload(false)
                }, 1000)
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        } else {
            console.warn('No file selected for upload.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        userInfo.profileImage = getImage;
        userInfo.mobile = getMblNumber
        try {
            let api = ApiNames.updateProfile;
            const response = await Axios.post(
                `${api}`, userInfo
            );
            setValue(true);
            getProfile();
            Toaster(response.data.message);
        } catch (error) {
            Toaster(error.response.data.error);
            console.error('Error uploading image:', error);
        }
    };

    const getProfile = async () => {
        try {
            let api = ApiNames.profile;
            const response = await Axios.get(
                `${api}`
            );

            // setUserInfo(response.data);
            setFlag(response.data)
            setsetNumber(response.data?.profile?.countryCode)
            setsetNumber(response.data?.profile?.countryCode)
            setPhoneNumber(response.data?.profile?.mobile)
            setLoginType(response.data?.profile?.type)
            setCountryCode(response.data?.profile?.countryCode)
            setTimeout(() => {
                if (response.data.profile.profileImage) {
                    setProfileImage(response.data?.profile?.profileImage);
                } else {
                    setProfileImage('https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg');
                }

            }, 1000)
            setValue(true);
        } catch (err) {
            console.log(err);
        }
    };

    const handleSubmitForm = async (e) => {

        e.preventDefault();

        let newErrors = {};
        if (formData.companyName.length < 1) {
            formData.companyName = 'optional';
        }
        Object.keys(formData).forEach((key) => {
            if (formData[key] === "") {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
            }
        });

        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            if (formData.companyName.length < 1) {
                formData.companyName = '-';
            }
            if (addressType === 'Other') {
                formData.type = customAddressType;
            } else {
                formData.type = addressType;
            }
            try {

                const response = await Axios.post(`${ApiNames.createAddress}`, formData);
                Toaster(response.data.message);
                getProfile()
                address()
                if (buttonRef.current) {
                    buttonRef.current.click();
                }
            } catch (err) {

                if (err.error.message) {
                    Toaster(err.error.message);
                } else {
                    Toaster(err.response?.data?.message);
                }

            }
        }
    };

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                let api = ApiNames.profile;
                const response = await Axios.get(
                    `${api}`
                );
                setUserInfo(response.data.profile);
                setValue(true);

                if (response.data.profile.profileImage) {
                    setProfileImage(response.data?.profile?.profileImage);
                } else {
                    setProfileImage('https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataFromApi();
    }, [changeValue]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.get(`${ApiNames.getAllCountries}`);
                setCountry(response.data);
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {

        address();
    }, []);
    const address = async () => {
        try {
            let api = ApiNames.getAddress;
            const response = await Axios.get(
                `${api}`
            );
            setAddress(response.data);
        } catch (err) {
            console.log(err);
        }
    }
    function editAddress(address) {
        setEditAddressId(address._id)
        setEditedAddressData({
            firstName: address.firstName || '',
            lastName: address.lastName || '',
            country: address.country || '',
            countryCode: address.countryCode || '',
            address: address.address || '',
            companyName: address.companyName || '',
            appartment: address.appartment || '',
            city: address.city || '',
            state: address.state || '',
            pincode: address.pincode || '',
            emailId: address.emailId || '',
            mobileNumber: address.mobileNumber || '',
            type: address.type || '',
            _id: address._id
            // Patch other fields here
        });
        setCustomAddressType(address.type)
    }
    const handleSubmitForms = async (e) => {
        e.preventDefault();
        try {

            editedAddressData._id = getAddresId
            let api = ApiNames.updateAddress;
            const response = await Axios.post(
                `${api}`, editedAddressData
            );
            console.log(response.data)
            address()
            Toaster(response.data.message)
            if (buttonRefs.current) {
                buttonRefs.current.click();
            }
        } catch (err) {
            if (err.error) {
                Toaster(err.error.error)
            } else {

                Toaster(err.response.data.error)
            }

        }
    };
    const handleEdiChange = (e) => {
        const { name, value } = e.target;
        setEditedAddressData({
            ...editedAddressData,
            [name]: value
        });
    };
    async function deleteAddress(data) {
        if (window.confirm("Sure to delete the Address")) {
            try {

                let api = ApiNames.deleteAddress;
                const response = await Axios.get(`${api}${data._id}`);
                Toaster(response.data.message);
                address();
            } catch (err) {
                Toaster(err.response.data.error);
            }
        }
    }

    const handleModelCloseAfterNewAddressAdded = () => {

        if (PopModal.current) {
            PopModal.current.click()
            address()

        }

        if (buttonRefs.current) {
            buttonRefs.current.click();
            address()
        }

    };


    useEffect(() => {
        // Listen for the custom event when the component mounts
        document.addEventListener('customAddressEvent', handleModelCloseAfterNewAddressAdded);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('customAddressEvent', handleModelCloseAfterNewAddressAdded);

        };
    }, []);


    return (
        <div className="container " style={{ minHeight: '100vh' }}>
            {/* <CreateAddress /> */}
            <div style={{ marginTop: 60 }}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Col xsm={3} md={3} lg={2} className="pe-0">
                            <Nav variant="pills" className="flex-column profile-top">
                                <Nav.Item>
                                    <Nav.Link eventKey="first" className="profile-items">
                                        Profile
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second" className="profile-items">
                                        Address
                                    </Nav.Link>
                                </Nav.Item>

                            </Nav>
                        </Col>
                        <Col xsm={9} md={9} lg={10} className="ps-0">
                            <Tab.Content className="rightProfile-contentTop">
                                <Tab.Pane eventKey="first">
                                    <div className="your-profile-details">
                                        <div className="row">
                                            <div className="col-md-10 col-lg-7 p-0">
                                                <div className="profile-details">
                                                    <h3 className="profile-heading">
                                                        Your Profile Details
                                                    </h3>
                                                    <div className="profile-details-image">
                                                        <div style={{ width: '60px', height: '60px', position: 'relative' }}>
                                                            <img
                                                                src={profileImage}
                                                                alt=""
                                                                className="profile-iamge"
                                                            />
                                                            {isProfileUpload ? (
                                                                <div className="spinner-border spinnerLoader" role="status">
                                                                    <span className="visually-hidden"></span>
                                                                </div>
                                                            ) : (
                                                                null
                                                            )}

                                                        </div>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={handleFileChange}
                                                            style={{ display: 'none' }}
                                                            ref={fileInputRef}
                                                        />
                                                        <p onClick={() => fileInputRef.current.click()} className="Profilehead">Change Avatar</p>
                                                    </div>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="profile-details-names">
                                                            <div className="row">
                                                                <div className="col-6 pe-0">
                                                                    <div className="profiles-names">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="First Name"
                                                                            className="first-name"
                                                                            name="firstName"
                                                                            value={userInfo.firstName}
                                                                            onChange={handleChanges}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="profiles-names">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Last Name"
                                                                            className="first-name"
                                                                            name="lastName"
                                                                            value={userInfo.lastName}
                                                                            onChange={handleChanges}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="display-password">
                                                            <input
                                                                type="text"
                                                                placeholder="Display Name"
                                                                className="display-password-name"
                                                                name="displayName"
                                                                value={userInfo.displayName}
                                                                onChange={handleChanges}
                                                            />
                                                        </div>
                                                        <p className="display-para">
                                                            This will be how your name will be displayed in the
                                                            account section and in reviews
                                                        </p>
                                                        {getLoginType === 'mobile' ? (
                                                            <div className="display-password">
                                                                <input
                                                                   style={{textTransform:'lowercase'}}
                                                                    type="email"
                                                                    placeholder="Email ID"
                                                                    className="display-password-name"
                                                                    name="emailId"
                                                                    value={userInfo.emailId}
                                                                    onChange={handleChanges}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="display-password">
                                                                <input 
                                                                style={{textTransform:'lowercase'}}
                                                                    type="email"
                                                                    placeholder="Email ID"
                                                                    disabled={true} 
                                                                    className="display-password-name "
                                                                    name="emailId"
                                                                    value={userInfo.emailId}
                                                                    onChange={handleChanges}
                                                                />
                                                            </div>
                                                        )}
                                                        <div style={{ marginTop: 40 }}>
                                                            <div className="contct-loginBelow">
                                                                <h2 className="adress-titlees">Contact</h2>
                                                            </div>
                                                            {getLoginType === 'mobile' ? (
                                                                <div className="register-number-header">
                                                                    <div className="registration-flag">
                                                                        <div className="registration-number">
                                                                            <div className="dropdown numberBox-top">
                                                                                <button
                                                                                    onClick={selectCOuntryS}
                                                                                    className="dropdown-toggle dropdown-flag-button"
                                                                                    type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false"
                                                                                >
                                                                                    <img
                                                                                        src={`data:image/png;base64,${getFlag.flag}`}
                                                                                        alt=""
                                                                                        className="registration-img"
                                                                                    />
                                                                                    <p className="register-number">+{getNumber}</p>
                                                                                </button>
                                                                                <ul
                                                                                    className="dropdown-menu countryCode-Dmenu"
                                                                                    aria-labelledby="dropdownMenuButton1"
                                                                                >
                                                                                    <li className="dropdown-flag-list search-box">

                                                                                        <div className="dropdowninsideimage">
                                                                                            <i className="fas fa-search registration-img"></i>

                                                                                            <input
                                                                                                ref={selectCountryNow}
                                                                                                type="text"
                                                                                                onKeyUp={handleKeyUp}
                                                                                                className="form-control"
                                                                                                placeholder="Select Country Name"
                                                                                                aria-describedby="Numberhelp"

                                                                                            />
                                                                                        </div>
                                                                                    </li>

                                                                                    {getcountryList.map((value, index) => (
                                                                                        <li key={index} onClick={() => GetCode(value)} className="dropdown-flag-list">
                                                                                            <a className="dropdown-item" >
                                                                                                <img
                                                                                                    src={`data:image/png;base64,${value.flag}`}
                                                                                                    alt=""
                                                                                                    className="registration-image"
                                                                                                />
                                                                                                <span className="flag-span">{value?.name}</span>
                                                                                            </a>
                                                                                        </li>
                                                                                    ))}

                                                                                </ul>
                                                                            </div>
                                                                        </div>

                                                                        <input
                                                                            type="number"
                                                                            className="form-control-input numberInput-box"
                                                                            placeholder="Please Enter The Mobile Number"
                                                                            id=""
                                                                            disabled={true}
                                                                            defaultValue={mobileNumber}
                                                                            onChange={handlePhoneNumberChange}
                                                                            aria-describedby="Numberhelp"
                                                                            required
                                                                        />



                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="register-number-header">
                                                                    <div className="registration-flag">
                                                                        <div className="registration-number">
                                                                            <div className="dropdown numberBox-top">
                                                                                <button
                                                                                    onClick={selectCOuntryS}
                                                                                    className="dropdown-toggle dropdown-flag-button"
                                                                                    type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false"
                                                                                >
                                                                                    <img
                                                                                        src={`data:image/png;base64,${getFlag.flag}`}
                                                                                        alt=""
                                                                                        className="registration-img"
                                                                                    />
                                                                                    <p className="register-number">+{getNumber}</p>
                                                                                </button>
                                                                                <ul
                                                                                    className="dropdown-menu countryCode-Dmenu"
                                                                                    aria-labelledby="dropdownMenuButton1"
                                                                                >
                                                                                    <li className="dropdown-flag-list search-box">

                                                                                        <div className="dropdowninsideimage">
                                                                                            <i className="fas fa-search registration-img"></i>

                                                                                            <input
                                                                                                ref={selectCountryNow}
                                                                                                type="text"
                                                                                                onKeyUp={handleKeyUp}
                                                                                                className="form-control"
                                                                                                placeholder="Select Country Name"
                                                                                                aria-describedby="Numberhelp"

                                                                                            />
                                                                                        </div>
                                                                                    </li>

                                                                                    {getcountryList.map((value, index) => (
                                                                                        <li key={index} onClick={() => GetCode(value)} className="dropdown-flag-list">
                                                                                            <a className="dropdown-item" >
                                                                                                <img
                                                                                                    src={`data:image/png;base64,${value.flag}`}
                                                                                                    alt=""
                                                                                                    className="registration-image"
                                                                                                />
                                                                                                <span className="flag-span">{value?.name}</span>
                                                                                            </a>
                                                                                        </li>
                                                                                    ))}

                                                                                </ul>
                                                                            </div>
                                                                        </div>

                                                                        <input
                                                                            type="number"
                                                                            className="form-control-input numberInput-box"
                                                                            placeholder="Please Enter The Mobile Number"
                                                                            id=""
                                                                            defaultValue={mobileNumber}
                                                                            onChange={handlePhoneNumberChange}
                                                                            aria-describedby="Numberhelp"
                                                                            required
                                                                        />



                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>


                                                        <div className="details-button">
                                                            <button type="submit" className="button-details">
                                                                Save Details
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="aims-delivery-address">
                                        <div className="row">
                                            <div className="col-sm-10 col-lg-7">
                                                <div className="delivery-address">
                                                    {getAddres.length > 0 ? (
                                                        <h1 className="deliveryaddress-head">
                                                            Delivery Address
                                                        </h1>
                                                    ) : (
                                                        <h1 className="deliveryaddress-head">
                                                            No Delivery Address Added
                                                        </h1>
                                                    )}


                                                </div>
                                                {getAddres.map((address, index) => (
                                                    <div key={index} className="card m-2">
                                                        <div className="card-body">
                                                            <div className="address-name">
                                                                <h3 className="customer-name">{address?.firstName} {address?.lastName}</h3>
                                                                {address?.type ? (
                                                                    <button className="address-button">{address?.type}</button>

                                                                ) : (
                                                                    null
                                                                )}
                                                            </div>
                                                            <div className="customer-number">
                                                                <div className="customer">
                                                                    <p className="customer-address">{address?.address}
                                                                        {address?.appartment} -    {address?.city}
                                                                    </p>
                                                                    <p className="customer-address">
                                                                        {address?.state} -{address?.pincode}
                                                                    </p>
                                                                    <p className="customer-address"> {address?.country}</p>
                                                                    <p className="customer-address">
                                                                        Mobile: {address?.countryCode} - {address?.mobileNumber}
                                                                    </p>
                                                                </div>
                                                                <div className="number-buttons">
                                                                    <button className="editbutton me-2"
                                                                        data-bs-toggle="modal" data-bs-target="#exampleModalAddressEdit" onClick={() => editAddress(address)}>Edit</button>
                                                                    <button onClick={() => deleteAddress(address)} className="editbutton">Delete</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="add-address">
                                                    <button data-bs-toggle="modal" data-bs-target="#exampleModalAddress" className="add-delivery-address">
                                                        Add Delivery Address
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>

                <div className="add-delivery-address">
                    <div
                        className="modal fade"
                        id="exampleModalAddress"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog  modal-dialog-centered">
                            <div className="modal-content ">
                                <div className="modal-header addAddresHeader">
                                    <h5 onClick={handleModelCloseAfterNewAddressAdded} className="modal-title addaddress" id="exampleModalLabel">
                                        Add delivery address
                                    </h5>
                                    <button ref={PopModal}
                                        type="button"
                                        className="btn-close modal-titleclose"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    />
                                </div>
                                <div style={{ padding: 0 }} className="modal-body">
                                    <div className="add-delivery-address-from">
                                        <CreateAddress />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="add-delivery-address">
                    <div
                        className="modal fade"
                        id="exampleModalAddressEdit"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Update delivery address
                                    </h5>
                                    <button ref={buttonRefs}
                                        type="button"
                                        className="btn-close modal-titleclose"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="modal-body">
                                    <div className="add-delivery-address-from">
                                        <EditAddress address={editedAddressData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Profile;