
import "swiper/css";
import { useEffect, useRef, useState } from "react";
import ApiNames from "../../Constants/ApiUrls";
import Axios from "../../utils/AxiosConfi";
import { useNavigate, useParams } from "react-router-dom";
import { useCart } from "../../Context/CartContext";
import Toaster from "../../utils/Toaster";

const OrderReturn = () => {
    const buttonRef = useRef(null);
    const buttonRefFinal = useRef(null);
    const [showModal, setShowModal] = useState(false);
    let navigation = useNavigate();
    const { orderId } = useParams()
    const [getOrderDetails, SetorderDetails] = useState([]);
    const [getBankDetails, setBankDetails] = useState([]);
    const [getCountry, setCountry] = useState([]);
    const [getAddress, SetAddress] = useState([]);
    const [addressType, setAddressType] = useState('');
    const [customAddressType, setCustomAddressType] = useState('');
    const [getAddressId, setFinalAddress] = useState('');
    const [getGetAddressDetails, SetAddressDetails] = useState([]);
    const { addToCart } = useCart()
    const [selectedReason, setSelectedReason] = useState('');
    const [getReference, setReference] = useState('');
    const [getType, setType] = useState(0);
    const [comment, setComment] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isSelectedAddress, setSelectedAddress] = useState(false);
    const [isPickupEnabled, setIsPickupEnabled] = useState(false);
    const [isPageValid, setPageValidation] = useState(null);
    const [getCards, setCardDetails] = useState([]);
    const [isFinalChecked, setFinalIsChecked] = useState(false);
    const accountNoRef = useRef(null);
    const ifscCodeRef = useRef(null);
    const [errors, setErrors] = useState({});
    function fixtheNuber(data) {
        if (data !== undefined && data !== null) {
            const numericData = parseFloat(data); // Convert input string to a number
            if (!isNaN(numericData)) { // Check if the conversion was successful
                return numericData.toFixed(2); // Round the number to two decimal places
            }
        }
        return ''; // Or any other default value or behavior you prefer
    }

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        country: "",
        countryCode: "",
        address: "",
        companyName: "",
        appartment: "",
        city: "",
        state: "",
        pincode: "",
        emailId: "",
        mobileNumber: ""
    });
    const [selectedImage, setSelectedImage] = useState(
        "/images/LandingPg/banner1.png"
    );

    const [getProduct, setProduct] = useState();
    const [getImage, setImage] = useState('');
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    useEffect(() => {
        const checkLoginStatus = async () => {
            try {

                let api = ApiNames.getMyOrderById
                const response = await Axios.get(
                    `${api}${orderId}`
                );

                if (response.data.orderDetails.deliveryStatus === 'Delivered') {
                    setPageValidation(false)

                    setProduct(response.data.productDetails)
                    SetorderDetails(response.data.orderDetails)
                    setCardDetails(response.data.paymentInfo)
                    SetAddressDetails(response.data?.orderDetails?.address[0])
                    setPageValidation(true)

                } else {
                    setPageValidation(false)
                }


            } catch (error) {
                console.error("Error checking login status:", error);
            }
        };

        checkLoginStatus();
    }, []);

    useEffect(() => {

        getAddres()
    }, [])
    const getAddres = async () => {
        try {
            let api = ApiNames.getAddress
            const response = await Axios.get(
                `${api}`
            );
            SetAddress(response.data)
        }


        catch (err) {
            console.log('error while getting addesss', err)
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.get(`${ApiNames.getAllCountries}`);
                setCountry(response.data)
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };

        fetchData();
    }, []);

    // review logics////
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmitForm = async (e) => {


        e.preventDefault();
        let newErrors = {};
        if (formData.companyName.length < 1) {
            formData.companyName = 'optonal'
        }
        Object.keys(formData).forEach((key) => {
            if (formData[key] === "") {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
            }
        });

        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            if (formData.companyName.length < 1) {
                formData.companyName = '-'
            }
            console.log(formData);
            // Here you can send the form data to the server or perform further actions
            console.log('Selected address type:', addressType);
            console.log('Custom address type:', customAddressType);
            if (addressType === 'Other') {
                formData.type = customAddressType
            } else {
                formData.type = addressType
            }
            try {
                const response = await Axios.post(`${ApiNames.createAddress}`, formData);
                console.log("responseresponse", response)
                Toaster(response.data.message)
                if (buttonRef.current) {
                    // Trigger a click event on the button
                    buttonRef.current.click();
                }
                getAddres()
            } catch (err) {

                Toaster(err.error.message)
            }
        }
    };
    function formatDate(dateString) {
        const inputDate = new Date(dateString);
        const options = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };
        return inputDate.toLocaleDateString('en-US', options);
    }



    const handleReasonChange = (event) => {
        const value = event.target.value;
        setSelectedReason(value);

        // Enable comment box if "Other" is selected
        if (value === 'Other') {
            setComment('');
            setIsPickupEnabled(false);
        } else {
            setComment('');
            setIsPickupEnabled(true);
        }
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleConfirmChange = (event) => {
        setIsChecked(!isChecked); // Toggle the value of isChecked

        setIsPickupEnabled(event.target.checked);
    };

    const handleSubmit = () => {


        if (isChecked === false) {
            Toaster("Select Confirmation checkbox")
            return
        }
        if (selectedReason === 'Other' && !comment) {
            Toaster("Please enter Reason")
            return
        }
        // Perform actions based on selectedReason and comment
        console.log('Selected Reason:', selectedReason);
        console.log('Comment:', comment);
        setType(1)
    };


    const handleAddressTypeChange = (event) => {
        const selectedType = event.target.value;
        setAddressType(selectedType);
        // Reset custom address type if user switches from Other
        if (selectedType !== 'Other') {
            setCustomAddressType('');
        }
    };
    const handleCustomAddressTypeChange = (event) => {
        setCustomAddressType(event.target.value);
    };


    function SelectAddress(data) {
        console.log(data)
        setFinalAddress(data)
        setSelectedAddress(true)
    }
    function CheckFinalPayment() {
         
        setType(2)
    }

    function selectConfirmationPayment(e) {
        setFinalIsChecked(e.target.checked);
        console.log(e.target.checked)
        // const [isFinalChecked, setFinalIsChecked] = useState(false);

    }

    async function finalSubmit(event) {
         
        const accountNo = accountNoRef?.current?.value;
        const ifscCode = ifscCodeRef?.current?.value;
        if(getCards.method === 'cod'){
            if(accountNo =='' || accountNo == undefined || accountNo ==null){
                Toaster("Bank accoun number required")
                return
            }
            if(ifscCode =='' || ifscCode == undefined || ifscCode ==null){
                Toaster("ifscCode required")
                return
            }
        }
        event.preventDefault(); // Prevent default form submission
    
        // Accessing input field values using refs
       
    
        // Logging the values to the console
        console.log('Bank Account No:', accountNo);
        console.log('IFSC Code:', ifscCode);
        if (isFinalChecked == true) {
            let reasons
            if (selectedReason === 'Other') {
                reasons = comment
            } else {
                reasons = selectedReason
            }
            let sendingObj = {
                orderId: orderId,
                reason: reasons,
                addressId: getAddressId,
                accountNumber:accountNo,
                ifscCode:ifscCode
            }
            try {
                const response = await Axios.post(`${ApiNames.returnTheProduct}`, sendingObj);
                console.log(response.data);
                setReference(response.data.referenceNumber)
                if (buttonRefFinal.current) {
                    // Trigger a click event on the button
                    buttonRefFinal.current.click();
                }
            } catch (err) {

                Toaster(err?.response?.data?.message)
                console.log(err)
            }
        } else {
            Toaster("Please check the Box for Confirmation")
        }
    }

    function gotoHome() {
        navigation(`/landing`)
    }

    function gotoExchange() {
        navigation(`/ExchangeOrder/${orderId}`)
    }
    const handleFormSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission
    
        // Accessing input field values using refs
        const accountNo = accountNoRef.current.value;
        const ifscCode = ifscCodeRef.current.value;
    
        // Logging the values to the console
        console.log('Bank Account No:', accountNo);
        console.log('IFSC Code:', ifscCode);
        let details = {

        }
        setBankDetails()
      };


    return (
        <>
            <div className="container" style={{marginTop:60}}>
                {isPageValid ? (
                    <div className="about-item">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="about-item-image">
                                    <img
                                        src={getProduct?.thumbnail}
                                        alt=""
                                        className="top-image-item"
                                    />
                                </div>
                                {/* <div className="downloadBtns">
                <div className="row">
                  <div className="col-md-6">
                    <button className="add-cart-btn">  Download Invoice</button>
                  </div>
                  {getOrderDetails?.deliveryStatus === 'Booking' ? (
                    <div className="col-md-6">
                      <button className="add-cart-btn"> Track Order</button>
                    </div>
                  ) : (
                    <div className="col-md-6">
                      <button data-bs-toggle="modal" data-bs-target="#exampleModal3" className="add-cart-btn"> Return/Exchange</button>
                    </div>
                  )}


                </div>
              </div> */}
                            </div>
                            <div className="col-md-7">
                                <div className="about-details">
                                    <p className="item-para">{getProduct?.title}
                                    </p>
                                    <p className="inclusiveline quantitys">Quantity : <span>{getOrderDetails?.quantity}</span></p>

                                    <p className="inclusiveline deleveryStatys">{getOrderDetails?.deliveryStatus}</p>
                <p className="inclusivelines">{formatDate(getOrderDetails.createdDate)}</p>
                                    <div className="quantity-details">

                                        <p className="quantity-para">Delivery Address</p>
                                        <p className="quantity-parasR">{getGetAddressDetails?.firstName} {getGetAddressDetails?.lastName}</p>
                                        <p className="quantity-paras">{getGetAddressDetails?.address}</p>
                                        <p className="quantity-paras">{getGetAddressDetails?.appartment}</p>
                                        <p className="quantity-paras">{getGetAddressDetails?.companyName}</p>
                                        <p className="quantity-paras">{getGetAddressDetails?.city}</p>
                                        <p className="quantity-paras">{getGetAddressDetails?.state}</p>
                                        <p className="quantity-paras">{getGetAddressDetails?.pincode}</p>
                                        <p className="quantity-paras">{getGetAddressDetails?.emailId}</p>
                                        <p className="quantity-paras">{getGetAddressDetails?.type}</p>
                                        <p className="quantity-paras">{getGetAddressDetails?.countryCode}- {getGetAddressDetails?.mobileNumber}</p>

                                    </div>


                                    <div className="rating-stars">
                                        <p className="write-review"> {fixtheNuber(getOrderDetails?.totalCost - getOrderDetails?.discountedPrice)} AED   <span data-bs-toggle="modal"
                                            data-bs-target="#exampleModal" className="write-Breakup">View Fare Breakup</span></p>
                                    </div>

                                    {getType === 0 ? (

                                        <div className="returnning">
                                            <p className="whyReturn">Why are you Returning</p>

                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="reason"
                                                    id="qualityIssue"
                                                    value="Quality Issue"
                                                    onChange={handleReasonChange}
                                                />
                                                <label className="form-check-label lableInfo" htmlFor="qualityIssue">
                                                    Quality Issue
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="reason"
                                                    id="qualityIssue"
                                                    value="I change my mind"
                                                    onChange={handleReasonChange}
                                                />
                                                <label className="form-check-label lableInfo" htmlFor="qualityIssue">
                                                    I change my mind
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="reason"
                                                    id="qualityIssue"
                                                    value="Wrong Product"
                                                    onChange={handleReasonChange}
                                                />
                                                <label className="form-check-label lableInfo" htmlFor="qualityIssue">
                                                    Wrong Product
                                                </label>
                                            </div>
                                            {/* Repeat similar structure for other options */}

                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="reason"
                                                    id="other"
                                                    value="Other"
                                                    onChange={handleReasonChange}
                                                />
                                                <label className="form-check-label lableInfo" htmlFor="other">
                                                    Other
                                                </label>
                                            </div>

                                            <div className="mb-3">
                                                <textarea
                                                    disabled={selectedReason !== 'Other'}
                                                    className="form-control textControl"
                                                    placeholder="Additional Comment"
                                                    id="comment"
                                                    rows="3"
                                                    value={comment}
                                                    onChange={handleCommentChange}
                                                ></textarea>
                                            </div>

                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="confirmProduct"
                                                    onChange={handleConfirmChange}
                                                    checked={isChecked} // Bind isChecked to the checked attribute
                                                />
                                                <label className="form-check-label lableInfo" htmlFor="confirmProduct">
                                                    I confirm that the product is unused with the original tags intact.
                                                </label>
                                            </div>

                                            <div className="textBrns">
                                                <button className="textBtnPick" onClick={handleSubmit} disabled={!isPickupEnabled}>
                                                    Pickup Details
                                                </button>
                                                <p onClick={gotoExchange} className="iwantExchange">I want Exchange</p>
                                            </div>
                                        </div>
                                    ) : getType === 1 ? (

                                        <div className="returnning">
                                            <p className="whyReturn">Select Address</p>


                                            <div>
                                                {getAddress.map((value, index) => (

                                                    <div key={index} onChange={() => SelectAddress(value._id)} className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id={`flexRadioDefault${index}`} />
                                                        <label className="form-check-label" htmlFor={`flexRadioDefault${index}`}>
                                                            <p className="addressItemsName"> {value?.firstName}    {value?.lastName}</p>
                                                            <p className="addressItems"> {value?.appartment}</p>
                                                            <p className="addressItems"> {value?.address}</p>
                                                            <p className="addressItems"> {value?.city}</p>
                                                            <p className="addressItems"> {value?.state} - {value?.pincode} </p>
                                                            <p className="addressItems"> {value?.country} </p>
                                                            <p className="addressItems"><span>Mobile No.</span> {value?.countryCode} {value?.mobileNumber} </p>
                                                        </label>
                                                    </div>
                                                ))}
                                                <div>
                                                    <button data-bs-toggle="modal" data-bs-target="#exampleModalAddress" className="changeAddress">Change Pickup Address</button>
                                                    {isSelectedAddress === true ? (
                                                        <button onClick={CheckFinalPayment} className="RefundProcess">Refund Process</button>
                                                    ) : (
                                                        <button className="RefundProcessDsiable">Refund Process</button>

                                                    )}
                                                </div>
                                            </div>




                                        </div>
                                    ) : getType === 2 ? (
                                        <div className="returnning">
                                            <p className="whyReturn">Refund Detail</p>

                                            <p className="youPaind">You paid <span className="priceDetailsRefund">{fixtheNuber(getOrderDetails.totalCost - getOrderDetails?.discountedPrice)}</span> AED by {getCards.method}</p>

                                            <div className="form-check mb-2">
                                                <input onChange={selectConfirmationPayment} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                <label className="form-check-label youPaind" for="flexRadioDefault1">
                                                    I accept <span className="priceDetailsRefund">{fixtheNuber(getOrderDetails?.totalCost - getOrderDetails?.discountedPrice)}</span> AED refunded to {getCards.method}
                                                </label>
                                            </div>

                                            <p className="contentsFinal">After the quality check is passed, a refund of {fixtheNuber(getOrderDetails?.totalCost - getOrderDetails?.discountedPrice)} AED will be initiated. {fixtheNuber(getOrderDetails.totalCost - getOrderDetails.discountedPrice)} AED will be created to {getCards.method} ending {getCards.card}. Will take 7-10 days for the money to be created once a refund is initiated.</p>
                                            {getCards.method === 'cod' ? (
                                              <form onSubmit={handleFormSubmit}>
                                              <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Bank account no.</label>
                                                {/* Assigning ref to the input field */}
                                                <input type="text" placeholder="Please enter Account number" className="form-control" id="exampleInputEmail1" ref={accountNoRef} aria-describedby="emailHelp" />
                                              </div>
                                              <div className="mb-3">
                                                <label htmlFor="exampleInputPassword1" className="form-label">IFSC CODE</label>
                                                {/* Assigning ref to the input field */}
                                                <input type="text" placeholder="Please Enter IFSC Code" className="form-control" id="exampleInputPassword1" ref={ifscCodeRef} />
                                              </div>
                                              <button type="submit" className="btn btn-primary">Submit</button>
                                            </form>
                                            ) :
                                                (
                                                    null
                                                )}


                                            <div className="textBrns">

                                                {isFinalChecked ? (
                                                    <button className="textBtnPick" onClick={finalSubmit}>
                                                        Confirm
                                                    </button>
                                                ) : (

                                                    <button className="RefundProcessDsiable">
                                                        Confirm
                                                    </button>
                                                )}

                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {/* Default case */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="container" style={{ height: '100vh' }}>
                        <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '50px' }}>Content Not Processable</p>
                    </div>
                )}


                {/* <!-- Modal --> */}
                <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down m-top">
                        <div className="modal-content M-content-below ">
                            <div className="modal-header M-header">
                                <h1 className="modal-title fs-5 payment-title" id="exampleModalLabel">
                                    Payment Information
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close close-icon"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body m-body">
                                <div className="payment-body">
                                    <ul className="payment-info-top">
                                        <li className="payment-list">
                                            <p className="left-priceText">{getProduct?.title.slice(0, 30)}...</p>
                                            <h1 className="amount-right">{getOrderDetails?.quantity} x {getOrderDetails.productPrice}  AED</h1>
                                        </li>
                                        <li className="payment-list">
                                            <p className="left-priceText">Tax Price</p>
                                            <h1 className="amount-right">{fixtheNuber(getOrderDetails.totalTaxCharges)} AED</h1>
                                        </li>
                                        <li className="payment-list">
                                            <p className="left-priceText">Shipping Fee</p>
                                            <h1 className="amount-right">{fixtheNuber(getOrderDetails.overallShippingCharges)} AED</h1>
                                        </li>
                                        <li className="payment-list border-btm">
                                            <p className="left-priceText">Total Amount</p>
                                            <h1 className="amount-right">{fixtheNuber(getOrderDetails.totalCost)} AED</h1>
                                        </li>
                                        <li className="payment-list border-btm">
                                            <p className="left-priceText">Coupon discount</p>
                                            <h1 className="amount-right"> -{fixtheNuber(getOrderDetails?.discountedPrice)} AED</h1>
                                        </li>
                                        <li className="payment-list border-btm">
                                            <p className="left-priceText">Grand Total</p>
                                            <h1 className="amount-right">{fixtheNuber(getOrderDetails?.totalCost - getOrderDetails?.discountedPrice)} AED</h1>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="invoice-btn">Download Invoice</button>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <!-- Modal Return or Exchange --> */}
                <div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-sm return-Mtop">
                        <div className="modal-content return-Mdata">
                            <div className="modal-header return-Mheader">
                                <h1 className="modal-title choose-title fs-5" id="exampleModalLabel">Choose any one</h1>
                                <button type="button" className="btn-close close-iccon" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body return-Mbody">
                                <div className="return-xchange-top">
                                    <div className="return-inside">  <p className="return-txt"><img className="returnBoxIcn" src="/images/returnIcon.svg" alt="..." />Return Product </p><p className="right-arrow"><i className="fas fa-chevron-right"></i></p></div>
                                    <div className="return-inside">  <p className="return-txt"><img className="returnBoxIcn" src="/images/exchange.svg" alt="..." />Exchange Product </p><p className="right-arrow"><i className="fas fa-chevron-right"></i></p></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* AddressAdd */}


                <div className="add-delivery-address">
                    <div
                        className="modal fade"
                        id="exampleModalAddress"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Add delivery address
                                    </h5>
                                    <button ref={buttonRef}
                                        type="button"
                                        className="btn-close modal-titleclose"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="modal-body">
                                    <div className="add-delivery-address-from">
                                        <form onSubmit={handleSubmitForm}>
                                            <div className="form">
                                                <div className="form-names">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-frist-name">
                                                                <input
                                                                    type="text"
                                                                    placeholder="First Name"
                                                                    className="first-name"
                                                                    name="firstName"
                                                                    value={formData.firstName}
                                                                    onChange={handleChange}
                                                                />
                                                                <span className="error">{errors.firstName}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-frist-name">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Last Name"
                                                                    className="first-name"
                                                                    name="lastName"
                                                                    value={formData.lastName}
                                                                    onChange={handleChange}
                                                                />
                                                                <span className="error">{errors.lastName}</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-names">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-frist-name">
                                                                <select className="form-select phone-number"
                                                                    name="countryCode"
                                                                    value={formData.countryCode}
                                                                    onChange={handleChange}

                                                                >
                                                                    <option disabled value={''} className="options">
                                                                        Mobile country code *
                                                                    </option>
                                                                    {getCountry.map((value, index) => (
                                                                        <option key={index} value={value.callingCode} className="options">
                                                                            {value.name} - {value.callingCode}
                                                                        </option>
                                                                    ))}

                                                                </select>
                                                                <span className="error">{errors.countryCode}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-frist-name">
                                                                <input
                                                                    placeholder="Mobile Number"
                                                                    className="first-name"
                                                                    name="mobileNumber"
                                                                    value={formData.mobileNumber}
                                                                    onChange={handleChange}
                                                                />
                                                                <span className="error">{errors.mobileNumber}</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-names">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-frist-name">
                                                                <input
                                                                    placeholder="Company name (optional)"
                                                                    className="first-name"
                                                                    name="companyName"
                                                                    value={formData.companyName}
                                                                    onChange={handleChange}
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-frist-name">
                                                                <input
                                                                    placeholder="Address"
                                                                    className="first-name"
                                                                    name="address"
                                                                    value={formData.address}
                                                                    onChange={handleChange}
                                                                />
                                                                <span className="error">{errors.address}</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" >
                                                    <div className="col-md-6">
                                                        <div className="form-number">
                                                            <input
                                                                placeholder="City"
                                                                className="phone-number"
                                                                name="city"
                                                                value={formData.city}
                                                                onChange={handleChange}
                                                            />
                                                            <span className="error">{errors.city}</span>

                                                        </div>

                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-number">
                                                            <input
                                                                placeholder="State"
                                                                className="phone-number"
                                                                name="state"
                                                                value={formData.state}
                                                                onChange={handleChange}
                                                            />
                                                            <span className="error">{errors.state}</span>

                                                        </div>

                                                    </div>

                                                </div>

                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="form-frist-name">
                                                            <select className="form-select phone-number"
                                                                name="country"
                                                                value={formData.country}
                                                                onChange={handleChange}

                                                            >
                                                                <option disabled value={''} className="options">
                                                                    Country *
                                                                </option>
                                                                {getCountry.map((value, index) => (
                                                                    <option key={index} value={value.name} className="options">
                                                                        {value.name}
                                                                    </option>
                                                                ))}


                                                            </select>
                                                            <span className="error">{errors.country}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-number">
                                                            <input

                                                                placeholder="appartment, Suite, etc"
                                                                className="phone-number"
                                                                name="appartment"
                                                                value={formData.appartment}
                                                                onChange={handleChange}
                                                            />
                                                            <span className="error">{errors.appartment}</span>

                                                        </div>
                                                    </div>

                                                </div>







                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-names">
                                                            <div >
                                                                <div >
                                                                    <div className="form-frist-name">
                                                                        <input
                                                                            placeholder="PIN Code"
                                                                            className="first-name"
                                                                            name="pincode"
                                                                            value={formData.pincode}
                                                                            onChange={handleChange}
                                                                        />
                                                                        <span className="error">{errors.pincode}</span>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-number">
                                                            <input
                                                                placeholder="Email ID"
                                                                className="phone-number"
                                                                type="email"
                                                                name="emailId"
                                                                value={formData.emailId}
                                                                onChange={handleChange}
                                                            />
                                                            <span className="error">{errors.emailId}</span>

                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-frist-name">
                                                            <select
                                                                className="form-select phone-number"
                                                                value={addressType}
                                                                onChange={handleAddressTypeChange}
                                                            >
                                                                <option value="" disabled>
                                                                    Address type *
                                                                </option>
                                                                <option value="Home">Home</option>
                                                                <option value="Office">Office</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                            <span className="error">{errors.country}</span>
                                                        </div>
                                                    </div>
                                                    {addressType === 'Other' && (
                                                        <div className="col-md-6">
                                                            <div className="form-number">
                                                                <input
                                                                    placeholder="Enter address type"
                                                                    className="phone-number"
                                                                    value={customAddressType}
                                                                    onChange={handleCustomAddressTypeChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>


                                                <div className="form-button">
                                                    <button className="save-button" type="submit">Save delivery address</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!--ReturnId displayed --> */}
                <p type="button" ref={buttonRefFinal} data-bs-toggle="modal" data-bs-target="#staticBackdrop">

                </p>

                <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-sm submit-Mtop">
                        <div class="modal-content submit-Mdata">
                            <div className="modal-body submit-Mbody">
                                <div className="submit-request-top">
                                    <p className="checked-lcon-inside"><span><i className="fas fa-check chek-icon"></i></span></p>
                                    <p className="return-submited">Return Request Submitted</p>
                                    <p className="ref-number">Reference number: {getReference}</p>
                                    <button onClick={gotoHome} data-bs-dismiss="modal" aria-label="Close" className="okkk-btn">Ok</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </>
    );
};

export default OrderReturn;
