import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Axios from '../../utils/AxiosConfi';
import ApiNames from "../../Constants/ApiUrls";
import Addreview from "../../Component/Addreview/Addreview";
import { useParams } from "react-router-dom";
import ProductItemsNew from "../../Component/ProductItems/ProductItemsNew";
import { useCart } from "../../Context/CartContext";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Welcome from "../../Component/Loaders/Welcome";
import Pagination from "./../Pagination/Pagination";
import PaginatedItems from "./../Pagination/Pagination";
import CategorySlider from "../../Component/Slider/categorySlider";





const Filter = () => {
  const { type, id } = useParams();
  const [priceRange, setPriceRange] = useState('Sort by Price');
  const [priceRangeVaue, setPriceRangeValue] = useState('Sort by Price');
  const [bestProducts, setBestProducts] = useState([]);
  const [selectedPeice, setSelectedPrice] = useState([]);
  const [getBannerData, setBannerData] = useState([]);

  const [getPriceProducts, setPriceProducts] = useState([]);
  const [getPriceDropd, setPriceDroped] = useState([]);
  const [getBannerImage, setBannerImage] = useState([]);
  const [getfilterList, setfilterList] = useState([]);
  const [getfilterRange, setfilterRange] = useState([]);
  const [selectPrice, setSelectPrice] = useState([]);
  const [getSort, setSort] = useState();
  const [getIsLoader, SetIsLoader] = useState(true);
  const [getfilterLoad, setfilterLoad] = useState(true);
  const [openIndexes, setOpenIndexes] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [priceRanges, setPriceRanges] = useState([0, 0]);
  const [startPrice, setStartPrice] = useState();
  const [endPrice, setEndPrice] = useState();

  useEffect(() => {
    setOpenIndexes(getfilterList.map((_, index) => index));
  }, [getfilterList]);

  const toggleAccordion = (index) => {
    setOpenIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((i) => i !== index);
      } else {
        return [...prevIndexes, index];
      }
    });
  };
  const { addToCart, whenLoginRefreshProduct, cartItems } = useCart()
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [id, type]);

  useEffect(() => {
    setfilterLoad(true)
    const getProducts = async () => {
      const api = ApiNames.filterProducts; // Update this with your API endpoint
      try {
        const body = {
          type: type,
          id: id,
          sort: getSort,
          filters: selectedFilters,

        };
        const response = await Axios.post(`${api}`, body);
        setBestProducts(response.data.products)
        getBanner(response.data.products[0].categoryId)
        setPriceProducts(response.data.products)
        SetIsLoader(false)
        setTimeout(() => {
          setfilterLoad(false)
        }, 500)
      } catch (error) {
        console.error('Error:', error);
      }
    };

    getProducts();
  }, [type, id, cartItems, selectedFilters]);


  const getBanner = async (id) => {

    const api = ApiNames.getCollectionByCategory;
    // let body = {
    //   id: id
    // }
    // const response = await Axios.post(`${api}`, body);
    // console.log("ddd", response.data)
    setBannerData(id)
  }

  useEffect(() => {
    const getProducts = async () => {
      const api = ApiNames.filtersList; // Update this with your API endpoint
      try {
        const body = {
          type: type,
          id: id,
          filters: selectedFilters,
          sort: priceRangeVaue
        };
        const response = await Axios.post(`${api}`, body);

        console.log(response.data)
        setfilterList(response.data.filtersList)
        debugger
        setfilterRange(response.data)
        setSelectPrice(response.data?.maxSellingPrice)
        let priceing = {
          startPrice: response.data?.minSellingPrice,
          endPrice: response.data?.maxSellingPrice,
        }
        await setPriceRanges([response.data?.minSellingPrice, response.data?.maxSellingPrice]);


        setSelectedPrice(priceing)
        setStartPrice(response.data?.minSellingPrice)
        setEndPrice(response.data?.maxSellingPrice)
      } catch (error) {
        console.error('Error:', error);
      }
    };

    getProducts();
  }, [type, id, cartItems, priceRangeVaue, selectedFilters]);

  async function fetFilterProducts(data) {
    if (data === 'price_asc') {
      setPriceRange('Low to High');
      setSort(1)
      const updatedFilters = selectedFilters.map(filter => {
        // Add the new key-value pair pricerange here
        return {
          ...filter,
        };
      });

      // Now updatedFilters contains the original objects with the new key added
      setSelectedFilters(updatedFilters);
    }
    if (data === 'price_desc') {
      setPriceRange('Hight to Low');
      setSort(-1)
      const updatedFilters = selectedFilters.map(filter => {
        // Add the new key-value pair pricerange here
        return {
          ...filter
        };
      });

      // Now updatedFilters contains the original objects with the new key added
      setSelectedFilters(updatedFilters);
    }
    if (data === 'lowTohighRating') {

      // Check if startPrice and endPrice already exist in selectedFilters
      const existingStartPriceIndex = selectedFilters.findIndex(filter => filter.key === 'startRating');
      const existingEndPriceIndex = selectedFilters.findIndex(filter => filter.key === 'endRating');

      // If startPrice already exists, update its value, otherwise add it to selectedFilters
      if (existingStartPriceIndex !== -1) {
        selectedFilters[existingStartPriceIndex].value = 1;
      } else {
        selectedFilters.push({
          key: 'startRating',
          value: 1
        });
      }

      // If endPrice already exists, update its value, otherwise add it to selectedFilters
      if (existingEndPriceIndex !== -1) {
        selectedFilters[existingEndPriceIndex].value = 5;
      } else {
        selectedFilters.push({
          key: 'endRating',
          value: 5
        });
      }

      setSelectedFilters([...selectedFilters]);
    }

  }


  function setManualnpuPrice() {
    let sendINfo = [getfilterRange?.minSellingPrice, getfilterRange?.maxSellingPrice]
    console.log(sendINfo)
  }

  function setPriceRange1(data) {
    const startPrice = data[0];
    const endPrice = data[1];
    setStartPrice(startPrice)
    setEndPrice(endPrice)
    let priceing = {
      startPrice: startPrice,
      endPrice: endPrice,
    }
    setSelectedPrice(priceing)
    const filteredProducts = getPriceProducts.filter(product => product.sellingPrice >= startPrice && product.sellingPrice <= endPrice);
    console.log(filteredProducts)
    setBestProducts([])
    setBestProducts(filteredProducts)
    // console.log(data);
    // setSelectPrice(data[1]);

    // // Check if startPrice and endPrice already exist in selectedFilters
    // const existingStartPriceIndex = selectedFilters.findIndex(filter => filter.key === 'startPrice');
    // const existingEndPriceIndex = selectedFilters.findIndex(filter => filter.key === 'endPrice');

    // // If startPrice already exists, update its value, otherwise add it to selectedFilters
    // if (existingStartPriceIndex !== -1) {
    //   selectedFilters[existingStartPriceIndex].value = data[0];
    // } else {
    //   selectedFilters.push({
    //     key: 'startPrice',
    //     value: data[0]
    //   });
    // }

    // // If endPrice already exists, update its value, otherwise add it to selectedFilters
    // if (existingEndPriceIndex !== -1) {
    //   selectedFilters[existingEndPriceIndex].value = data[1];
    // } else {
    //   selectedFilters.push({
    //     key: 'endPrice',
    //     value: data[1]
    //   });
    // }

    // setSelectedFilters([...selectedFilters]);
  }


  const clearAllFilters = () => {
    setSelectedFilters([]);
  };

  const handleFilterSelect = (filter, keyName) => {

    setSelectedFilters((prevFilters) => {
      const filterObj = { key: keyName, value: filter };
      const filterExists = prevFilters.some((item) => item.key === keyName && item.value === filter);

      if (filterExists) {
        // Filter is already selected, so remove it
        return prevFilters.filter((item) => !(item.key === keyName && item.value === filter));
      } else {
        // Filter is not selected, so add it
        return [...prevFilters, filterObj];
      }
    });
    console.log(selectedFilters)
  };


  const [currentPage, setCurrentPage] = useState(1);

  const handlePaginate = pageNumber => {
    setCurrentPage(pageNumber);
  };


  const handleStartPriceChange = (event) => {
    setStartPrice(event.target.value);
  };

  const handleEndPriceChange = (event) => {
    setEndPrice(event.target.value);
  };

  const handleGoClick = () => {
    setManualnpuPrice(startPrice, endPrice);
    let sendPrice = [startPrice, endPrice]

    setPriceRange1(sendPrice)

  };





  return (
    <div style={{ minHeight: '100vh' }}>

      <div>
        {getIsLoader === true ? (
          <div>
            {/* <Welcome /> */}
          </div>
        ) : (
          <div >
            <div style={{ minHeight: '100vh' }}>
              <div className="filterSections-below">

                <div className="mid-products">
                  <Addreview />

                </div>




              </div>
              <div style={{ padding: '0px 20px' }} className="row justify-content-center m-0 p-0">
                <div>
                  <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom" className="filterBtns"><i className="fas fa-filter" ></i>Filters</button>

                </div>
                <div className="col-md-3 filterSwich">
                  <div className="filterSection">
                    <div className="filterByPrice">Filter by Price </div>
                    <div className="filterByPrice">(Selected {selectedPeice.startPrice} AED to {selectedPeice.endPrice} AED)  </div>
                    <div>
                      <p className="customisezPrice">Price (AED)</p>
                      <div className="custmoseInpf">
                        <input
                          className="numberI"
                          value={startPrice}
                          onChange={handleStartPriceChange}
                          type="number"
                        />
                        <p className="toPrice">TO</p>
                        <input
                          className="numberI"
                          value={endPrice}
                          onChange={handleEndPriceChange}
                          type="number"
                        />
                        <p onClick={handleGoClick} className="toPriceGo">GO</p>
                      </div>
                    </div>
                    <Slider
                      className="range-slidess"
                      range
                      min={getfilterRange?.minSellingPrice}
                      max={getfilterRange?.maxSellingPrice}
                      defaultValue={priceRanges}
                      onChange={(value) => setPriceRange1(value)}
                    />
                    <div className="slidePrices">
                      <label className="priceTxt">From:<span className="priceAccess"> {getfilterRange?.minSellingPrice} AED </span> </label>
                      <label className="priceTxt">To:  <span className="priceAccess">  {selectPrice} AED </span> </label>
                    </div>

                    <div>
                    <div className="accordionSection">
      <div className="accordion accordionBox" id="accordionPanelsStayOpenExample">
        {getfilterList.map((item, index) => (
          <div className="accordion-item acd-items" key={index}>
            <h2 className="accordion-header">
              <button
                className={`accordion-button acd-buttons ${openIndexes.includes(index) ? '' : 'collapsed'}`}
                type="button"
                onClick={() => toggleAccordion(index)}
                aria-expanded={openIndexes.includes(index) ? 'true' : 'false'}
              >
                {item?.key}
              </button>
            </h2>
            <div className={`accordion-collapse collapse ${openIndexes.includes(index) ? 'show' : ''}`}>
              <div className="accordion-body acd-content">
                {item?.values?.map((value, i) => (
                  <div key={i} className="form-check">
                    <input
                      className="form-check-input form-check-Data"
                      type="checkbox"
                      value={value.value}
                      id={value.value}
                      checked={selectedFilters.some((filter) => filter.key === item.key && filter.value === value.value)}
                      onChange={() => handleFilterSelect(value.value, item.key)}
                    />
                    <label className="form-check-label" htmlFor={value.value}>
                      {value.value} ({value.count})
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

                    </div>

                  </div>
                </div>
                <div className="col-md-9">
                  <CategorySlider data={getBannerData} />
                  {bestProducts.length === 0 ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100' }}>No items found</div>
                  ) : (
                    <div>
                      {/* <img className="bannerImg"  src={getBannerData?.bannerImg} /> */}

                      <div className="selectedProducts">
                        {selectedFilters.length > 0 && (
                          <div>

                            <div className="selectedFilters">

                              {selectedFilters.map((filter, index) => (
                                <div key={index}>
                                  <label onClick={() => handleFilterSelect(filter.value, filter.key)} className="filterName">
                                    {filter.key}: {filter.value}
                                    <span>
                                      <img src="/images/menubar/closeIcn.svg" className="closeIcon" />
                                    </span>
                                  </label>
                                </div>
                              ))}
                              <label onClick={clearAllFilters} className="clearAllBtn">
                                <span>
                                  <img src="/images/menubar/filter-x.svg" className="closeIcon" />
                                </span>
                                Clear Filters
                              </label>
                            </div>

                          </div>
                        )}
                        <div className="TotalProducts">

                          <label className="foundedProducts">We found <span className="counting">{bestProducts.length}</span> items for you!</label>
                          <div>
                            <div className="right-relevence">
                              <Dropdown className="relvence-Ddown">
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="relvence-Inside">
                                  <span className="upDwn-arrw">
                                    <img src="/images/range.svg" className="ranges" />
                                    <span className="relvance-filterTitle">{priceRange}</span>
                                  </span>
                                  <span className="upArrow-Icn">
                                    <i className="fas fa-angle-down arwUpIcon"></i>
                                  </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="Relvence-Top">
                                  <Dropdown.Item onClick={() => fetFilterProducts('price_asc')} className="relvence-Dropitems" >Price: Low to High</Dropdown.Item>
                                  <Dropdown.Item onClick={() => fetFilterProducts('price_desc')} className="relvence-Dropitems" >Price: High to Low</Dropdown.Item>
                                  {/* <Dropdown.Item onClick={() => fetFilterProducts('lowTohighRating')} className="relvence-Dropitems" >Rating: Low to High</Dropdown.Item>
                                <Dropdown.Item onClick={() => fetFilterProducts('highTolowRating')} className="relvence-Dropitems" >Rating: High to Low</Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>


                            </div>
                          </div>
                        </div>
                        {getfilterLoad === true ? (
                          <div className="loaderMini">
                            <Welcome />

                          </div>
                        ) : (

                          <div>

                            <ProductItemsNew productObj={bestProducts} />
                            {/* <PaginatedItems itemsPerPage={3} items={bestProducts} />, */}
                          </div>

                        )}





                      </div>

                    </div>


                  )}

                </div>
              </div>

            </div>
          </div>
        )}
      </div>


      {/*  */}


      <div className="offcanvas offcanvas-bottom" style={{ height: '70vh' }} tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
        <div className="offcanvas-header">
          {/* <h5 class="offcanvas-title" id="offcanvasBottomLabel">Offcanvas bottom</h5> */}
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body small">
          <div className="filterSection">
            <div className="filterByPrice">Filter by Price </div>
            <div className="filterByPrice">(Selected {selectedPeice.startPrice} AED to {selectedPeice.endPrice} AED)  </div>
            <div>
              <p className="customisezPrice">Price (AED)</p>
              <div className="custmoseInpf">
                <input
                  className="numberI"
                  value={startPrice}
                  onChange={handleStartPriceChange}
                  type="number"
                />
                <p className="toPrice">TO</p>
                <input
                  className="numberI"
                  value={endPrice}
                  onChange={handleEndPriceChange}
                  type="number"
                />
                <p onClick={handleGoClick} className="toPriceGo">GO</p>
              </div>
            </div>
            <Slider
              className="range-slidess"
              range
              min={getfilterRange?.minSellingPrice}
              max={getfilterRange?.maxSellingPrice}
              defaultValue={priceRanges}
              onChange={(value) => setPriceRange1(value)}
            />
            <div className="slidePrices">
              <label className="priceTxt">From:<span className="priceAccess"> {getfilterRange?.minSellingPrice} AED </span> </label>
              <label className="priceTxt">To:  <span className="priceAccess">  {selectPrice} AED </span> </label>
            </div>

            <div>
              <div className="accordionSection">
                <div className="accordion accordionBox" id="accordionPanelsStayOpenExample">
                  {getfilterList.map((item, index) => (
                    <div className="accordion-item acd-items" key={index}>
                      <h2 className="accordion-header">
                        <button
                          className={`accordion-button acd-buttons ${openIndexes.includes(index) ? '' : 'collapsed'}`}
                          type="button"
                          onClick={() => toggleAccordion(index)}
                          aria-expanded={openIndexes.includes(index) ? 'true' : 'false'}
                        >
                          {item?.key}
                        </button>
                      </h2>
                      <div className={`accordion-collapse collapse  ${openIndexes.includes(index) ? 'show' : ''}`}>
                        <div className="accordion-body acd-content">
                          {item?.values?.map((value, i) => (
                            <div key={i} className="form-check">
                              <input
                                className="form-check-input form-check-Data"
                                type="checkbox"
                                value={value.value}
                                id={value.value}
                                checked={selectedFilters.some((filter) => filter.key === item.key && filter.value === value.value)}
                                onChange={() => handleFilterSelect(value.value, item.key)}
                              />
                              <label className="form-check-label" htmlFor={value.value}>
                                {value.value} ({value.count})
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

    </div>
  );
};

export default Filter;
