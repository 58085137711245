// MySkeleton.js
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const MySkeleton = ({ count, height, width, circle }) => {
  return (
    <Skeleton count={count} height={height} width={width} circle={circle} />
  );
};

// Define default props in case they are not provided
MySkeleton.defaultProps = {
  count: 1,
  height: 20,
  width: 100,
  circle: false
};

export default MySkeleton;