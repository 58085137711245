import React, { useEffect } from "react";
import Axios from '../../utils/AxiosConfi';
import ApiNames from "../../Constants/ApiUrls";

const Faq = () => {

  
  useEffect(() => {
    const getProducts = async () => {
      const api = ApiNames.getFaqBySection; // Update this with your API endpoint
      try {
   
        const response = await Axios.get(`${api}`);
        console.log(response.data)
      } catch (error) {
        console.error('Error:', error);
      }
    };

    getProducts();
  }, []);

    useEffect(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      });
  return (
    <div className="faq-top">
      <div className="faq-top">
        <div className="container">
          <h1 className="faq">Frequently Asked Question</h1>
          <p className="faq-text">
            Our FAQ section provides quick answers to common queries, saving you
            time and hassle. Easily find solutions to your questions about our
            products, services, and policies. Streamline your experience with us
            and get the information you need in just a few clicks.
          </p>
        </div>
      </div>
      <div className="faq-seb-section">
      <div className="container">
        <div className="general-questions">
          <ul class="nav nav-tabs general-ul-top" id="myTab" role="tablist">
            <li class="nav-item btn-lists" role="presentation">
              <button
                class="nav-link genral-btn active"
                id="General-tab"
                data-bs-toggle="tab"
                data-bs-target="#General"
                type="button"
                role="tab"
                aria-controls="General"
                aria-selected="true"
              >
                General
              </button>
            </li>
            <li class="nav-item btn-lists" role="presentation">
              <button
                class="nav-link genral-btn"
                id="Product-tab"
                data-bs-toggle="tab"
                data-bs-target="#Product"
                type="button"
                role="tab"
                aria-controls="Product"
                aria-selected="false"
              >
                Product
              </button>
            </li>
          </ul>
          <div class="tab-content tab-data-below" id="myTabContent">
            <div
              class="tab-pane fade show active general-data-inside"
              id="General"
              role="tabpanel"
              aria-labelledby="General-tab"
            >
              <div className="question-top">
                <h2 className="question-header">General Questions</h2>
                <div class="accordion general-accrdion-top" id="accordionExample">
                  <div class="accordion-item general-item">
                    <h2 class="accordion-header header-acrdion" id="headingOne">
                      <button
                        class="accordion-button product-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Third Party Products
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show acrdion-colapse-top"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body acrdion-body-data">
                      Flip Deals Estore allows Third-party sellers to list and
                        sell their products at FlipDeals. In each such
                        case, this is indicated on the respective product detail
                        page, while Flip Deals Estore helps facilitate
                        transactions that are carried out on the Flip Deals
                        Estore marketplace. Products that are listed in Flipdeals Estore in 3P Products category are from different
                        manufacturers/distributors. As part of Flip Deals Estore
                        services, we offer a wide range of products through the
                        Third-party seller category and we will recommend
                        products that may interest you through our digital
                        marketing platform. Customers are requested to read
                        every detail such as ingredients (If any), applications
                        and warnings before making a purchase.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item general-item">
                    <h2 class="accordion-header header-acrdion" id="headingTwo">
                      <button
                        class="accordion-button collapsed product-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Product Purchase
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse acrdion-colapse-top"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body acrdion-body-data">
                       
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just.
                       
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item general-item">
                    <h2 class="accordion-header header-acrdion" id="headingThree">
                      <button
                        class="accordion-button collapsed product-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Product Purchase
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse acrdion-colapse-top"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body acrdion-body-data">
                       
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <div
                class="tab-pane fade"
                id="Product"
                role="tabpanel"
                aria-labelledby="Product-tab"
              >
                product data
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;