import React, { useEffect, useRef, useState } from "react";
import Axios from "../../utils/AxiosConfi";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ApiNames from "../../Constants/ApiUrls";
import { useNavigate } from "react-router-dom";

const Searchbar = ({ className }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [inputText, setInputText] = useState('');
    const [showDropdown, setShowDropdown] = useState(true);
    const navigate = useNavigate();
    const [searchName, setSearchName] = useState("");
    const [getselectedCategory, setselectedCategory] = useState("All Categories");
    const [getselectedCategoryId, setselectedCategoryId] = useState("");
    const [categories, setCategories] = useState([]);
    const [getListSearch, setListSearch] = useState([]);
    const [getOriginalData, setOriginalData] = useState([]);
    const [getSearchItems, setSearchItems] = useState([]);
    const [opacity, setOpacity] = useState(0);
    const [getCategory, setCategory] = useState([]);
    const [searchResult, setSearchResults] = useState(false);
    const [showCart, setShowCart] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    //   const { addToCart } = useCart();
    function addToCarts(id) {
        setShowCart(false);
        navigate(`/ProductView/${id}`);
    }
    const handleSearch = async (event) => {
        try {
            let query = event.target.value;

            let id = getselectedCategoryId;
            let sendObj = {
                q: query,
                id: getselectedCategoryId
            }
            setSearchName(query);
            let response = await Axios.post(`${ApiNames.searchItems}`, sendObj);
            setSearchItems(response.data);
            if (response.data.length > 0) {
                setOpacity(1);
            }
            setSearchResults(true);
        } catch (err) {
            setSearchName("");
            setOpacity(0);
            setSearchResults(false);
        }
    };
    const handleKeyUp = async (query) => {
        //    console.log(query)
        console.log('Key pressed:', query.key);
        setInputText(query);
        if (query.length > 0) {
            try {
                const response = await Axios.get(`${ApiNames.searchProducts}${query}`);
                setOriginalData(response.data)
                let searchData = [];
                debugger
                if (response.data.length <= 0) {
                    hideNoItemsFound()

                }
                response.data.forEach((value) => {
                    searchData.push(value.name);
                });
                debugger
                console.log(searchData);
                setListSearch(searchData);
                // setShowDropdown(true); 
            } catch (err) {
                console.error(err);
            }
        } else {
            setShowDropdown(false); // Hide dropdown if query is empty
        }
    };
    useEffect(() => {
        const handleSearch = async () => {
            try {
                const response = await Axios.get(`${ApiNames.getAllCategory}`);
                setCategory(response.data);
                setCategories(response.data);
            } catch (err) {
                console.log(err);
            }
        };

        handleSearch();
    }, []);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredCategories = categories.filter(category =>
        category.categoryName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    function selectedCategory(data) {
        setselectedCategory(data.categoryName)
        setselectedCategoryId(data._id)
    }

    const handleSelect = (selected) => {
        console.log('Selected item:', selected); // Log the selected item to the console
        setInputText(selected[0]);
        if (selected[0] !== undefined) {
            gotoFilterPage(selected[0])
        }else{
            hideNoItemsFound()
        }
    };
    const typeaheadRef = useRef(null);
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const inputValue = typeaheadRef.current?.getInput().value;
            console.log('Enter pressed with value:', inputValue);
            setInputText(inputValue)
            if (inputValue !== undefined) {
                gotoFilterPage(inputValue)
            }else{
                hideNoItemsFound()
            }

        }
    };

    function hideNoItemsFound(){
        // var text = "No mathing products";
        // var carTypeahead = document.getElementById('car-typeahead');
        // if (carTypeahead) {
        //     var innerText = carTypeahead.innerText || carTypeahead.textContent; // Get the text content inside the div
    
        //     // Check if the inner text contains the specified text
        //     if (innerText.includes(text)) {
        //         carTypeahead.style.display = 'none'; // Hide the div if the text is found
        //     } else {
        //         carTypeahead.style.display = 'block'; // Show the div if the text is not found
        //     }
        // }
    }

    function gotoFilterPage(query) {
        let navigated = false; // Flag to track if navigation has occurred
    
        const found = getOriginalData.some((value) => {
            if (value.name === query) {
                if (value.type === 'category') {
                    navigate(`/Filter/1/${value._id}`);
                    return true; // Found and navigated
                } 
                if (value.type === 'subCategory') {
                    navigate(`/Filter/6/${value._id}`);
                    return true; // Found and navigated
                } 
                if (value.type === 'tag') {
                    navigate(`/Filter/7/${query}`);
                    return true; // Found and navigated
                } 
            }
            return false; // Not found or not navigated
        });
    
        if (!found) {
            // If no match was found in getOriginalData, navigate to /Filter/7/{query}
            navigate(`/Filter/7/${query}`);
        }
    }
    
    
    
    return (
        <>
            <div className={`search-container ${className}`} >
                <div className=" serachbar-form">
                    <div className="dropdown select-wrapper">
                        <button
                            className="btn  dropdown-toggle custom-select"
                            type="button"
                            id="dropdownMenuButton3"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            {getselectedCategory}
                            <img
                                className="down-arrow"
                                src="/images/iconsImages/downarw.svg"
                            />
                        </button>
                        <ul
                            className="dropdown-menu category-dropdwn-box"
                            aria-labelledby="dropdownMenuButton3"
                        >
                            <div className="category-data-box">
                                <input
                                    className="category-searchInput"
                                    type="text"
                                    id="category_search_text"
                                    placeholder="Search for items"
                                    value={searchQuery}
                                    onChange={handleSearchInputChange}
                                />
                                <div className="category-lists-top">
                                    {filteredCategories.map((value, index) => (
                                        <li key={index} className="category-lists" onClick={() => selectedCategory(value)}>
                                            <a className="list-items-inside">{value?.categoryName}</a>
                                        </li>
                                    ))}


                                </div>
                            </div>
                        </ul>
                    </div>
                    <div className=" search-Drpdown">
                        <form
                        >
                            {/* <input
                                onKeyUp={handleSearch}
                                type="text"
                                className="searchInput"
                                id="header_search_text"
                                placeholder="What are you shopping for?"
                                aria-label="Search"
                            /> */}

                            <Typeahead
                                id="car-typeahead"
                                options={getListSearch}
                                placeholder="What are you shopping for?"
                                minLength={1}
                                emptyLabel="No mathing products"
                                maxResults={30}
                                isLoading={isLoading}
                                paginate={false}
                                onChange={handleSelect}
                                highlightOnlyResult={false}
                                onInputChange={(text) => handleKeyUp(text)}
                                className="typeahead searchInput"
                                isMenuShown={showDropdown}
                                ref={typeaheadRef}
                                onKeyDown={handleKeyPress}
                            />
                            <span type="submit" className="search-btn">
                                <img
                                    src="/images/menubar/search.svg"
                                    className="serch-image"
                                    alt=""
                                />
                            </span>
                        </form>


                    </div>
                </div>
            </div>

        </>
    );
};

export default Searchbar;















