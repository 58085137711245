import { useEffect, useState } from "react";
import Axios from '../../utils/AxiosConfi';
import ApiNames from "../../Constants/ApiUrls";
import { useCart } from "../../Context/CartContext";
import { Navigate, useNavigate } from "react-router-dom";
import ProductItems from "../ProductItems/ProductItems";

const RecommendedProducts = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [onDataReturn, setonDataReturn] = useState('');
    const [recommendedProducts, setRecommendedProducts] = useState([]);
    const [recomendedId, setRecomendedId] = useState(0);
    const [isLoginUser, setIsLogin] = useState(null);
    const navigate = useNavigate();

    const { addToCart, whenLoginRefreshProduct, cartItems } = useCart()


    async function addOrRemoverWishList(product) {
        let token = await localStorage.getItem('token');
        if (token) {
            setIsLogin(true);
        } else {
            setIsLogin(false);
        }

        if (isLoginUser) {
            let sendData = {
                productId: product._id
            };
            let api = ApiNames.user_WishList;
            try {
                const response = await Axios.post(api, sendData);
                setonDataReturn(response.data.message);
                // Toggle isWishlist property
                product.isWishlist = !product.isWishlist;
                addToCart(response.data.message);
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            document.dispatchEvent(new Event('customEvent'));
        }
    }
    useEffect(() => {


      getRecommended(recomendedId);
    }, [recomendedId,cartItems]);
    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                let token = await localStorage.getItem('token');
                if (token) {
                    setIsLogin(true);
                } else {
                    setIsLogin(false);
                }
            } catch (error) {
                console.error("Error checking login status:", error);
                // Handle the error as needed
            }
        };

        checkLoginStatus();
    }, []);

    function addToCarts(id) {
        navigate(`/ProductView/${id}`)
    }


    async function addToCartsProduct(id, type) {
        try {
            let api = ApiNames.addToCart;
            let body = {
                productId: id,
                type: type
            };
            const response = await Axios.post(
                `${process.env.REACT_APP_API_KEY}${api}`, body
            );
            addToCart(response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const getRecommended = async (recomendedId) => {
        const url = `${ApiNames.getrecommendedProducts}${recomendedId}`;
        setIsLoading(true); // Set isLoading to true to show the skeleton loader
        try {
            // Introducing a delay of 3 seconds for demonstration purposes
            await new Promise(resolve => setTimeout(resolve, 0));

            const response = await Axios.get(url);
            setRecommendedProducts(response.data.productsList);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false); // Set isLoading to false once data is loaded
        }
    };


    return (
        <>
              <p className="text-title recomentdedForYou">Recommended for You</p>

            <ul className="nav nav-tabs recomendedpills-data" id="myTab" role="tablist">
                <li className="nav-item recomendedpills-li" role="presentation">
                    <button
                        className="nav-link recomendedpills-btn active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        onClick={() => getRecommended(0)}
                    >
                        Top Selling Products
                    </button>
                </li>
                <li className="nav-item recomendedpills-li" role="presentation">
                    <button
                        className="nav-link recomendedpills-btn"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={() => getRecommended(1)}
                    >
                        Trending Products
                    </button>
                </li>
                <li className="nav-item recomendedpills-li" role="presentation">
                    <button
                        className="nav-link recomendedpills-btn"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contact"
                        type="button"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        onClick={() => getRecommended(2)}
                    >
                        Featured Products
                    </button>
                </li>
                <li className="nav-item recomendedpills-li" role="presentation">
                    <button
                        className="nav-link recomendedpills-btn"
                        id="contact1-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contact1"
                        type="button"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        onClick={() => getRecommended(3)}
                    >
                        Top Rated Products
                    </button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                >
                    <div className="row g-3">

                        <ProductItems productObj={recommendedProducts} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default RecommendedProducts