import React, { useEffect, useState } from 'react'
import Axios from '../../utils/AxiosConfi';
import ApiNames from "../../Constants/ApiUrls";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useCart } from "../../Context/CartContext";
import Toaster from '../../utils/Toaster';

const BlogData = () => {
  let navigation = useNavigate();
  const { id } = useParams();
  const [blogData, setblogData] = useState([]);
  const [blogsData, setBlogsData] = useState([]);
  const [blogsId, setBlogsId] = useState("");
  const { cartItems, selectRefresh, addToCart, removeFromCart } = useCart()
  const [subscribemail, setsubscribemail] = useState("");
  const [subscribemailErr, setsubscribemailErr] = useState("");

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setBlogsId(id)
    blogcontent(id)
  },[id]);


  function blogcontent(id){
    const url = ApiNames.getBlogData + id;
    const body = {
      _id: id,
    };

    Axios.get(url, body)
      .then((res) => {
        setblogData(res.data[0]);
        

      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    const getBlogs = async () => {
      try {
        let api = ApiNames.getBlogs
        const response = await Axios.get(
          `${api}`
        );

        setBlogsData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getBlogs();
  }, [selectRefresh]);


  const handleChange = (event) => {
    // console.log("---->", event.target.name, event.target.value);
    if (event.target.name === "subscribemail") {
      let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i;
      if (reg.test(event.target.value)) {
        setsubscribemailErr("");
      } else {
        setsubscribemailErr("please enter correct email");
      }
      setsubscribemail(event.target.value);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (subscribemail === "" || subscribemailErr !== "") {
      alert("Please fill all input fields");
      return;
    } // api call
    const url = ApiNames.subscribeForm;
    const body = {
      email: subscribemail,
    };
    const headerTypes = {
      "content-type": "application/json",
    };
    Axios.post(url, body, { headers: headerTypes })
      .then((res) => {
        if (res.data.code === "success") {
          setsubscribemail("");
          Toaster(res.data.message);
        } else {
          setsubscribemail("");
          Toaster(res.data.message);
        }
      })
      .catch((error) => {
        Toaster(error.response.data.message);
      });
  };

  return (
    <>

      <div className="allblogs-data">
        <div className="container">
          <div className="allblogs-header">
            <h5 className="allblogs-para">Blog</h5>
          </div>
          <div className="allblogs-information">
          <form onSubmit={handleSubmit}>
                <div className="allblogs-search">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="allblogs-search-inside"
                    id="email"
                    value={subscribemail}
                    name="subscribemail"
                    onChange={handleChange}

                  />
                  <button className="allblogs-button">Subscribe</button>
                </div>
              </form>
          </div>

          <div className="desort-board">
            <div className="row">
              <div className=" col-md-12 col-lg-7">
                <div className="desort-card">
                  <div className="card desort-card-item">
                    <div className="card-image-top">
                      <img
                        src={blogData?.image}
                        className="desort-img-top"
                        alt="..."
                      />
                    </div>
                    <div className="card-body">
                      <p className="desort-whitten-para">{blogData?.date}</p>
                      <h1 className="desort-compounding-head">
                        {blogData?.title}
                      </h1>
                      <p className="desort-text-whitten">
                        {blogData?.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-md-12 col-lg-5">
                <div className="desort-right-card">
                  {blogsData.map((products, index) => (
                    <NavLink key={index} style={{ textDecoration: "none" }} to={`/bloginfo/${products._id}`}>
                      <div className="card mb-4">
                        <div className="row g-0">
                          <div className="col-md-5">
                            <div className="desort-card-image">
                              <img
                                src={products?.image}
                                className="img-desort-left"
                                alt="no-data"
                              />
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="card-body">
                              <p className="card-para">{products?.date}</p>
                              <h5 className="card-title"> {products?.title}</h5>
                            
                              <button className="card-button">
                                Read more
                               
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  )
                  )}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default BlogData