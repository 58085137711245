import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide,autoplay } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import Axios from '../../utils/AxiosConfi';
// import required modules
// import { Navigation } from "swiper/modules";
import ApiNames from "../../Constants/ApiUrls";
import { useNavigate } from "react-router-dom";
import {Navigation, Pagination, Autoplay  } from "swiper/modules";
import MySkeleton from "../../Component/Loaders/MySkeleton";

const Productslidr = () => {
  let navigation = useNavigate();

  const [getBanner, setBanner] = useState([]);
  const loopMode = getBanner.length > 1;
  useEffect(() => {
    const getAllCategory = async () => {
      try {
        let url = ApiNames.getBannerImages
        const response = await Axios.get(
          `${url}`
        );
        let sectionData = []
        response.data.forEach((value) => {
          if (value.sectionId === 1) {
            sectionData.push(value)
          }
        })
        setBanner(sectionData);
      } catch (error) {
        console.log(error);
      }
    };
    getAllCategory();
  }, []);


  function gotoSection(data) {

    if (data.bannerType === "Product") {
      navigation(`/ProductView/${data.categoryId}`)
    } else {
      navigation(`/Filter/1/${data.categoryId}`)

    }
  }

  return (
    <div className="top-Banner">
   <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 4500,
        disableOnInteraction: false,
      }}
   
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      {getBanner.map((banner) => (
        <SwiperSlide key={banner._id}>
          <div className="banner-imageTop">
            <img
              onClick={() => { gotoSection(banner) }}
              className="banner-images"
              src={banner.image || <MySkeleton /> }
              alt="no-image"
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
    </div>
  );
};

export default Productslidr;
