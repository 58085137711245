import React from 'react'

const Addreview = () => {
  return (
    <div className="add-reviewmodal">
  <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">   
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">Add Review</h5>
          <button type="button" className="btn-close modal-titleclose" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
        <div className="add-review">
          <p className="addfristreview">Be the first to review “Apple iPad Pro 2022 (4th Gen) 11 inch Space Gray 1TB 5G – International Version”</p>
          <p className="addfristreviewpara">Your email address will not be published.</p>
          <div className="addreviewstars"></div>
          <div className="details">
            <div className="reviewdetails">
              <input type="" className="reviewdetailsname" placeholder="Name" id="" />
            </div>
            <div className="reviewdetails">
              <input type="" className="reviewdetailsname" placeholder="Email" id="" />
            </div>
            <div className="reviewDescribedetails">
              <input type="" className="Describedetails" placeholder="Describe your review" id="" />
            </div>
            <div className="reviewuploadimage">
              <input className="uploadimage" type="file" placeholder="Upload Image" id="" />
              {/* <!-- <img src="/images/uploadimage.jpg" className="uploadimagearrow"/> --> */}
              <i className="fas fa-upload upload-arrow " ></i>
            </div>
          </div>
        </div>
        </div>
        <div className=" submit-review">
          <button type="button" className="btn submit-reviewbutton">Submit Review</button>
        </div>
      </div>
    </div>
  </div>
  
</div>
  )
}

export default Addreview