import CryptoJS from 'crypto-js';
import ApiNames from '../Constants/ApiUrls';


// Function to encrypt sensitive data
const encryptData = (data) => {
  // Example encryption using CryptoJS
  const encryptedData = CryptoJS.AES.encrypt(data, ApiNames.secretKey).toString();
  return encryptedData;
}

export default encryptData    