import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';

const PaginatedItems = ({ itemsPerPage, items }) => {
    const [itemOffset, setItemOffset] = useState(0);
    
    const pageCount = Math.ceil(items.length / itemsPerPage);
  
    const handlePageClick = (selected) => {
        const newOffset = selected * itemsPerPage;
        console.log(`User requested page number ${selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
    };
  
    const currentItems = items.slice(itemOffset, itemOffset + itemsPerPage);
  
    return (
        <div>
            <Items currentItems={currentItems} />
            <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={({ selected }) => handlePageClick(selected)}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel="< previous"
                marginPagesDisplayed={1}
                containerClassName="pagination"
                activeClassName="active"
            />
        </div>
    );
};

const Items = ({ currentItems }) => (
    <div>
        {currentItems.map(item => (
            <div key={item._id.$oid} className="item">
                <h2>{item.title}</h2>
                <p>{item.description}</p>
                <img src={item.thumbnail} alt={item.title} />
                {/* Render any other item details you want */}
            </div>
        ))}
    </div>
);

export default PaginatedItems;
