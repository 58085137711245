import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import ApiNames from '../../Constants/ApiUrls';
import Axios from "../../utils/AxiosConfi";
import Toaster from '../../utils/Toaster';
import { useCart } from '../../Context/CartContext';

const PayVerification = () => {
    // const { resultIndicator } = useParams();
    const [isLoadingCount, setLoadingCount] = useState(true);
    let currentRate = useRef(0)
    const { cartItems, selectRefresh, addToCart, removeFromCart } = useCart();
    const location = useLocation();
    let navigation = useNavigate();
    const resultIndicator = location.search;

    useEffect(() => {
        console.log('useEffect called');
        getInfo();
    }, []);

    const getInfo = async () => {
        debugger
        if (currentRate.current === 2) {
            return;
        }
        let info = {
            key: resultIndicator
        };
        currentRate.current = 2
        let api = ApiNames.paymentVerification;
        try {
            debugger
            const response = await Axios.post(`${api}`, info);
            if (response.data.status === true) {
                Toaster(response.data.message);
                navigation(`/Orders`);
                addToCart('');
            }
            setLoadingCount(false);
        } catch (err) {
            Toaster(err.response?.data?.error || 'An error occurred');
            navigation(`/Orders`);
        }
    };

    return (
        <div className='PaymentVerification'>
            <div className="spinner-border" role="status"></div>
            <span className="visually-hidden">Booking order</span>
        </div>
    );
};

export default PayVerification;
