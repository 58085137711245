import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import Axios from '../../utils/AxiosConfi';
import ApiNames from "../../Constants/ApiUrls";
import { useNavigate } from "react-router-dom";
import MySkeleton from "../Loaders/MySkeleton";
import { Pagination, Navigation } from 'swiper/modules';

const CategorySlider = ({ data }) => {
    const [getBanner, setBanner] = useState('');
    const [getItems, setItems] = useState([]);
    const [getcombinedList, setcombinedList] = useState([]);

    let navigation = useNavigate();
    const getRoute = (id) => {
        navigation(`/Filter/4/${id}`)
    }

    const getNewRoute = (data) => {
        navigation(`/Filter/6/${data?._id}`)

    }
    useEffect(() => {
        if (data) {
            debugger
            setBanner(data);
        }
    }, [data]);

    useEffect(() => {
        const getBannerData = async () => {
            debugger
            if (getBanner.length > 0) {
                let body = {
                    id: getBanner
                };
                const api = ApiNames.getCollectionByCategory;
                try {
                    const response = await Axios.post(`${api}`, body);
                    console.log("response:", response.data);
                    debugger
                    setItems(response.data?.getCollection)
                    setcombinedList(response.data?.combinedList)
                    // set your state or perform other actions with response.data
                } catch (error) {
                    console.error("Error fetching banner data:", error);
                }
            }
        };
        getBannerData();
    }, [getBanner]);

    return (
        <>
            {getBanner.length > 0 ? (
                <div className="categorySwiper">
                    <Swiper
                        slidesPerView={1}
                        centeredSlides={true}
                        spaceBetween={30}
                        loop={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {getItems.map((value, index) => (
                            <SwiperSlide key={index}>
                                <img className="bannerImg" onClick={() => getRoute(value._id)} src={value?.bigImg} alt={`Image not found`} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            ) : 
          null
            
            }
            <div className="categorySwiper">
                <div className="subCatelist">

                    <Swiper
                        slidesPerView={6}
                        spaceBetween={16}
                        grabCursor={true}
                        autoplay={{
                            delay: 4500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            0: { // 0px and up - mobile
                                slidesPerView: 4,
                            },
                            768: { // 768px and up - larger screens
                                slidesPerView: 8,
                            },
                            820: { // 768px and up - larger screens
                                slidesPerView: 8,
                            },
                        }}
                        loop={true}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                    >

                        {getcombinedList.map((values, index) => (
                            <SwiperSlide key={index}>
                                <div onClick={() => getNewRoute(values)} className="subPack">
                                    <img className="subList" src={values?.image} />
                                    <label className="topName">{values?.name}</label>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>


                </div>
            </div>


        </>
    );
};

export default CategorySlider;
